import React from "react"
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../../components/SEO"
import Header from "../../components/Header"
import PageNav from "../../components/PageNav"

export default function Index() {
    const intl = useIntl()
    return (
        <div>
            <Seo
                title={intl.formatMessage({ id: "works.title.seo" })}
            />
            <Header />

            <div className="sir-breadcrumbs">
                <Link class="text-sir-black-50" to="/"><FormattedMessage id="global.sir" /></Link>
                <span class="text-sir-black-25 mx-1">/</span>
                <FormattedMessage id="global.menu.works" />
            </div>

            <div className="sir-section">
                <div className="w-full flex justify-start mb-24">
                    <div className="w-1/2">
                        <Link to="/works/light-i">
                            <StaticImage src="../../images/works/01.png" alt={intl.formatMessage({ id: "works.work1.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work1.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work1.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work1.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work1.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work1.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-end mb-24">
                    <div className="w-1/2">
                        <Link to="/works/landscape-xviii">
                            <StaticImage src="../../images/works/02.png" alt={intl.formatMessage({ id: "works.work2.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work2.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work2.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work2.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work2.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work2.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-start mb-24">
                    <div className="w-1/2">
                        <Link to="/works/object-x">
                            <StaticImage src="../../images/works/03.png" alt={intl.formatMessage({ id: "works.work3.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work3.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work3.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work3.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work3.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work3.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-end mb-24">
                    <div className="w-1/2">
                        <Link to="/works/illusion-iii"></Link>
                        <StaticImage src="../../images/works/04.png" alt={intl.formatMessage({ id: "works.work4.title" })} />
                        <div className="body-s uppercase mt-4">
                            <span className="mr-4"><FormattedMessage id="works.work4.title" /></span>
                            <span className="opacity-50 mr-4"><FormattedMessage id="works.work4.year" /></span>
                            <span className="opacity-50 mr-4"><FormattedMessage id="works.work4.medium" /></span>
                            <span className="opacity-50 mr-4"><FormattedMessage id="works.work4.dimensions" /></span>
                            <span className="opacity-50"><FormattedMessage id="works.work4.location" /></span>
                        </div>
                    </div>
                </div>

                <div className="w-full flex justify-start mb-24">
                    <div className="w-1/2">
                        <Link to="/works/space-ii">
                            <StaticImage src="../../images/works/05.png" alt={intl.formatMessage({ id: "works.work5.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work5.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work5.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work5.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work5.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work5.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-end mb-24">
                    <div className="w-1/2">
                        <Link to="/works/catastrophe-iii">
                            <StaticImage src="../../images/works/06.png" alt={intl.formatMessage({ id: "works.work6.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work6.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work6.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work6.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work6.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work6.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-start mb-24">
                    <div className="w-1/2">
                        <Link to="/works/light-iii">
                            <StaticImage src="../../images/works/07.png" alt={intl.formatMessage({ id: "works.work7.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work7.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work7.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work7.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work7.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work7.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-end mb-24">
                    <div className="w-1/2">
                        <Link to="/works/geometry-xi">
                            <StaticImage src="../../images/works/08.png" alt={intl.formatMessage({ id: "works.work8.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work8.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work8.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work8.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work8.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work8.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-start mb-24">
                    <div className="w-1/2">
                        <Link to="/works/catastrophe-vii">
                            <StaticImage src="../../images/works/09.png" alt={intl.formatMessage({ id: "works.work9.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work9.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work9.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work9.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work9.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work9.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-end mb-24">
                    <div className="w-1/2">
                        <Link to="/works/space-iii"></Link>
                        <StaticImage src="../../images/works/10.png" alt={intl.formatMessage({ id: "works.work10.title" })} />
                        <div className="body-s uppercase mt-4">
                            <span className="mr-4"><FormattedMessage id="works.work10.title" /></span>
                            <span className="opacity-50 mr-4"><FormattedMessage id="works.work10.year" /></span>
                            <span className="opacity-50 mr-4"><FormattedMessage id="works.work10.medium" /></span>
                            <span className="opacity-50 mr-4"><FormattedMessage id="works.work10.dimensions" /></span>
                            <span className="opacity-50"><FormattedMessage id="works.work10.location" /></span>
                        </div>
                    </div>
                </div>

                <div className="w-full flex justify-start mb-24">
                    <div className="w-1/2">
                        <Link to="/works/landscape-xxv">
                            <StaticImage src="../../images/works/11.png" alt={intl.formatMessage({ id: "works.work11.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work11.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work11.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work11.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work11.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work1.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-end mb-24">
                    <div className="w-1/2">
                        <Link to="/works/geometry-xvii">
                            <StaticImage src="../../images/works/12.png" alt={intl.formatMessage({ id: "works.work12.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work12.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work12.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work12.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work12.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work12.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-start mb-24">
                    <div className="w-1/2">
                        <Link to="/works/phantom-x">
                            <StaticImage src="../../images/works/13.png" alt={intl.formatMessage({ id: "works.work13.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work13.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work13.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work13.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work13.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work13.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-end mb-24 ml-8">
                    <div className="w-1/2">
                        <Link to="/works/light-i">
                            <StaticImage src="../../images/works/14.png" alt={intl.formatMessage({ id: "works.work14.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work14.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work14.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work14.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work14.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work14.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-start mb-24">
                    <div className="w-1/2">
                        <Link to="/works/geometry-xiv"></Link>
                        <StaticImage src="../../images/works/15.png" alt={intl.formatMessage({ id: "works.work15.title" })} />
                        <div className="body-s uppercase mt-4">
                            <span className="mr-4"><FormattedMessage id="works.work15.title" /></span>
                            <span className="opacity-50 mr-4"><FormattedMessage id="works.work15.year" /></span>
                            <span className="opacity-50 mr-4"><FormattedMessage id="works.work15.medium" /></span>
                            <span className="opacity-50 mr-4"><FormattedMessage id="works.work15.dimensions" /></span>
                            <span className="opacity-50"><FormattedMessage id="works.work15.location" /></span>
                        </div>
                    </div>
                </div>

                <div className="w-full flex justify-end mb-24">
                    <div className="w-1/2">
                        <Link to="/works/geometry-xii">
                            <StaticImage src="../../images/works/16.png" alt={intl.formatMessage({ id: "works.work16.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work16.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work16.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work16.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work16.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work16.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-start mb-24">
                    <div className="w-1/2">
                        <Link to="/works/9-squares">
                            <StaticImage src="../../images/works/17.png" alt={intl.formatMessage({ id: "works.work17.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work17.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work17.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work17.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work17.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work17.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-center mb-24">
                    <div className="w-1/2">
                        <Link to="/works/circle">
                            <StaticImage src="../../images/works/18.png" alt={intl.formatMessage({ id: "works.work18.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work18.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work18.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work18.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work18.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work18.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-start mb-24">
                    <div className="w-1/2">
                        <Link to="/works/still-life-with-a-mirror">
                            <StaticImage src="../../images/works/19.png" alt={intl.formatMessage({ id: "works.work19.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work19.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work19.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work19.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work19.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work19.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w-full flex justify-end mb-24">
                    <div className="w-1/2">
                        <Link to="/works/old-venus">
                            <StaticImage src="../../images/works/20.png" alt={intl.formatMessage({ id: "works.work20.title" })} />
                            <div className="body-s uppercase mt-4">
                                <span className="mr-4"><FormattedMessage id="works.work20.title" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work20.year" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work20.medium" /></span>
                                <span className="opacity-50 mr-4"><FormattedMessage id="works.work20.dimensions" /></span>
                                <span className="opacity-50"><FormattedMessage id="works.work20.location" /></span>
                            </div>
                        </Link>
                    </div>
                </div>


            </div>

            <PageNav />
        </div>
    )
}
